/* eslint-disable @typescript-eslint/no-unsafe-argument */
import React, { useEffect, useState } from "react";

import { graphql } from "gatsby";

import stations from "../../../stations.json";
import { apiFetch } from "../../apiBindings";
import Error404 from "../../components/pages/errors/Error404";
import useStore from "../../components/pages/GasStations/GasStation/store";
import { navigate } from "../../components/shared/LinkComponent";
import { LoadingIndicatorContainer } from "../../components/shared/LoadingIndicator";
import TankstellePage from "../../templates/tankstelle/[slug]";

const DynamicTankstellePage = ({ params: { slug }, data: { page } }) => {
    const {
        gasStationSlug,
        setGasStation,
        setGasStationError,
        setPrices,
        startLoading,
        stopLoading,
        isLoading,
        gasStation,
    } = useStore();

    const [stillLoading, setStillLoading] = useState(false);

    useEffect(() => {
        if (gasStationSlug === slug) return;

        const { id } = slug.match(/\|(?<id>\d+)$/)?.groups ?? {};

        if (id) {
            const station = stations.find(
                (station) => station.id === Number(id)
            );

            if (station) {
                setGasStationError(false);
                setGasStation(station, slug);
                setStillLoading(false);
                return;
            }
        }

        startLoading("initial");
        const timeout = setTimeout(() => {
            setStillLoading(true);
        }, 2000);

        apiFetch(`/gas-stations/${slug}`)
            .then((response) => response.json())
            .then((response) => {
                clearTimeout(timeout);
                if (response.status && response.status === "error") {
                    setGasStationError(response.message);
                } else {
                    setGasStationError(false);
                    setGasStation(response, slug);
                    setStillLoading(false);
                }
                stopLoading("initial");
            })
            .catch(() =>
                setGasStationError(
                    "Es ist ein Fehler beim Laden der Daten aufgetreten."
                )
            );

        return () => {
            if (timeout) {
                clearTimeout(timeout);
            }
        };
    }, [
        gasStationSlug,
        setGasStation,
        setGasStationError,
        setPrices,
        setStillLoading,
        slug,
        startLoading,
        stopLoading,
    ]);

    const gasStationIsLoading =
        isLoading("initial") || !gasStationSlug || gasStationSlug !== slug;

    if (!gasStationIsLoading) {
        if (
            !gasStation ||
            (Array.isArray(gasStation) && gasStation.length === 0)
        ) {
            return <Error404 />;
        }

        // As we're encoding a path _segment_, we need to encode those symbols
        // which separate path segments from each other ('/') and those which
        // separate the path from subsequent URI components ('?' and '#')
        const newSlug = gasStation.slug.replace(/[/?#]/g, encodeURIComponent);

        if (slug !== newSlug && slug !== newSlug.replace("|", "%7C")) {
            void navigate(`/tankstelle/${newSlug}`, { replace: true });

            return null;
        }
    }

    return gasStationIsLoading ? (
        stillLoading ? (
            <LoadingIndicatorContainer />
        ) : null
    ) : (
        <TankstellePage
            pageContext={{
                gasStation,
                slug,
            }}
            data={{ page }}
        />
    );
};
export default DynamicTankstellePage;

export const query = graphql`
    query {
        page: pageItemsYaml(templateKey: { eq: "pageItems/tankstelle" }) {
            ...PageItem
        }
    }
`;
